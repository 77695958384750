import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC065p7ZsTqawoJot2yYifs4G4IUBru1J0",
  authDomain: "otanertech.firebaseapp.com",
  databaseURL: "https://otanertech.firebaseio.com",
  projectId: "otanertech",
  storageBucket: "otanertech.appspot.com",
  messagingSenderId: "387282585347",
  appId: "1:387282585347:web:966e811d112835233ecb36",
  measurementId: "G-TQV364FFTT"
}

// firebase.initializeApp(firebaseConfig);
// export default firebase;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export { auth, googleProvider };
export default firebase;