import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import './Target.css';

export default function Target({ data, selectedCodes, onCodeSelected, onCodeRemoved, onCodeStatusChange, currentRemovedCodeActive }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(selectedCodes.includes(data.shortName));

    const toggleCard = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (onCodeStatusChange && currentRemovedCodeActive === data.shortName) {
            selectTarget();
        }
    }, [onCodeStatusChange]);

    const selectTarget = () => {
        setIsSelected(!isSelected);
        !isSelected ? onCodeSelected(data.shortName) : onCodeRemoved(data.shortName);
    };

    return (
        <div className="target-container">
            <div className="target-card">
                <div className="target-title-name-section">
                    <p className="name-symbol">{data.shortName} ({data.symbol})</p>
                </div>
                <div className="target-info">
                    <div className='target-select-to-compare-section'>
                        {isSelected && selectedCodes.length > 0 ? (
                            <p onClick={selectTarget} className="selected-text">Selected</p>
                        ) : (
                            <p onClick={selectTarget} className="select-text">Select to Compare</p>
                        )}
                    </div>
                    <div className='target-price-number-section'>
                        <p className="target-price-text">Target price:</p>
                        <p className="new-price">${data.newPrice}</p>
                    </div>
                    <div className='target-select-to-compare-section'>
                        {isExpanded ? 
                            <ChevronUp onClick={toggleCard} className="icon" />
                            :
                            <ChevronDown onClick={toggleCard} className="icon" />
                        }
                    </div>
                </div>
            </div>
            {isExpanded && (
                <div className="content-modal">
                    <p style={{fontSize:'12px', fontWeight:'600'}}>Percent Dividend: {data.propertyCalculatedDividend}</p>
                </div>
            )}
        </div>
    );
}
