import React, { useState } from 'react';
import firebase from '../Firebase/firebaseConnection';
import axios from 'axios';
import { Eye, EyeOff } from 'react-feather';
import logoNextStock from '../img/Next Stock.png';
import ggoogleIcon from '../img/Ggoogle-icon.jpg';
import './Signup.css';

const API_ENDPOINT = "https://moonsigns-backend-0fa96cd0f5d0.herokuapp.com/v1/next-stock/";
// const API_ENDPOINT = "http://localhost:5001/v1/next-stock/";

export default function Signup({ setPage }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    function formatDate(date) {
        return date.toISOString().slice(0, 19); // Format as YYYY-MM-DDTHH:mm:ss
    }

    function addOneMonthAndFormatDate(date) {
        let nextDate = new Date(date);
        nextDate.setMonth(nextDate.getMonth() + 1);
        return formatDate(nextDate);
    }

    async function register() {
        if (password !== password2 || email.length < 3 || !email.includes("@") || name.length < 3) {
            alert('Invalid credentials: \n\n- Check your password.\n- Revise your email address.\n- You name should contain at least 3 characters. ');
            return;
        }

        const formattedStartDate = formatDate(new Date());
        const formattedEndDate = addOneMonthAndFormatDate(new Date());
        const creationTypeUserPlan = "premium";
        const creationTypeUserStatus = "member";

        const randomCode = Math.floor(100000 + Math.random() * 900000);

        try {

            const value = await firebase.auth().createUserWithEmailAndPassword(email, password);
            await firebase.database().ref('Users').child(value.user.uid).set({
                name: name,
                email: email,
                authcode: "",
                authtime: "",
                accountValidationCode: randomCode,
                accountValidationRequired: true,
                status: creationTypeUserStatus,
                plan: {
                    type: creationTypeUserPlan,
                    expire: formattedEndDate,
                    start: formattedStartDate,
                    collection_method: "",
                    id: "",
                    invoice_pdf: "",
                    paid: "",
                    period: { end: 0, start: 0 },
                    product: "",
                    subscription_id: "",
                    subscription_canceled: "",
                    subscription_status: "",
                    subscription_cancelation_date: "",
                    currency: "",
                    payment_failed: "",
                    payment_failed_date: "",
                    payment_failed_verified: "",
                    payment_failed_link: ""
                },
                stripeCustomerId: ""
            });

            saveOnLocalStorage(value.user.uid, email, creationTypeUserStatus, creationTypeUserPlan);

            const response = await axios.post(API_ENDPOINT + 'send-signup-confirmation-email', {
                email:email,
                name:name,
                text:String(randomCode),
                subject:""
            });

            if (response.data.status != 'success') {
                alert('Sorry. Something went wrong.\n\n Activation email failed.\n\nPlease contact us at nextstockdev@gmail.com.');
                return;
            }

            setName('');
            setEmail('');
            setPassword('');
            setPassword2('');

            setPage("AccountConfirmation");
            // setPage("Home");
        } catch (error) {
            switch (error.code) {
                case 'auth/email-already-in-use':
                    alert("This email is already registered. Please use a different email or sign in.");
                    break;
                case 'auth/invalid-email':
                    alert("Invalid email format. Please enter a valid email.");
                    break;
                case 'auth/weak-password':
                    alert("Password is too weak. Please use at least 6 characters.");
                    break;
                default:
                    alert("Sorry! Something went wrong. Please, try again. Error: " + error.message);
            }
            console.error("Signup error:", error);
        }
    }

    function saveOnLocalStorage(uid, uemail, status, planType) {
        localStorage.setItem('userUid', uid);
        localStorage.setItem('userEmail', uemail);
        localStorage.setItem('userStatus', status);
        localStorage.setItem('userPlanType', planType);
    }

    return (
        <div className="container">
            <div className="overlay">
                <div className="signup-box">
                    <img src={logoNextStock} alt="Logo" className="logo" />
                    
                    <div className="title-section"> <label>Username</label> </div>
                    
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

                    <div className="title-section"> <label>Email</label> </div>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                    <div className="title-section"> <label>Password</label> </div>
                    <div className="password-container">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className="toggle-password" onClick={togglePasswordVisibility}>
                            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                        </button>
                    </div>

                    <div className="title-section"> <label>Confirm Password</label> </div>
                    <div className="password-container">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                        <button className="toggle-password" onClick={togglePasswordVisibility}>
                            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                        </button>
                    </div>

                    <button className="signup-btn" onClick={register}>Sign up</button>

                    <div className="links">
                        <p  style={{color:'#444', fontSize:'11px'}}>Already have an account? <span onClick={() => setPage("Signin")} className='bottom-texts'> Sign in</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
