import React from 'react';
// import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
    return (
        <div style={{padding:'10%', paddingTop:'10px'}}>
            <h1>Privacy Policy</h1>
            <h2>Next Stock</h2>
            
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You respond to our advertisements and tells You about Your privacy rights and how the law protects You. We use Your Personal Data to contact and support you, as well as to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
            
            <h3>Interpretation and Definitions</h3>
            <h4>Interpretation</h4>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            
            <h4>Definitions</h4>
            <ul>
                <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Next Stock.</li>
                <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                <li><strong>Service</strong> refers to the advertisement, website, or application.</li>
                <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
                <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
                <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service.</li>
            </ul>
            
            <h3>Collecting and Using Your Personal Data</h3>
            <h4>Types of Data Collected</h4>
            <h5>Personal Data</h5>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <ul>
                <li>First name and last name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Location details such as your address, city, or country</li>
                <li>Any other data such as personal preferences, requirements, or comments</li>
            </ul>
            
            <h4>Use of Your Personal Data</h4>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li>To provide and maintain our Service.</li>
                <li>To manage Your Account and registration.</li>
                <li>For the performance of a contract.</li>
                <li>To contact You through various means.</li>
                <li>To provide You with news, special offers and general information.</li>
                <li>To manage Your requests.</li>
                <li>For business transfers or other corporate changes.</li>
                <li>For data analysis and improving our services.</li>
            </ul>
            
            <h3>Retention of Your Personal Data</h3>
            <p>The Company will retain Your Personal Data only for as long as necessary to comply with legal obligations, resolve disputes, and enforce policies.</p>
            
            <h3>Security of Your Personal Data</h3>
            <p>We strive to use commercially acceptable means to protect Your Personal Data, but no method is 100% secure.</p>
            
            <h3 style={{color:'#ff8282'}}>Facebook Data Deletion Instructions</h3>
            <p>If you wish to delete your data associated with our Facebook application, please follow these steps:</p>
            <ol>
                <li>Go to <a href="https://www.facebook.com/settings?tab=applications" target="_blank" rel="noopener noreferrer">Facebook App Settings</a>.</li>
                <li>Find "Next Stock" in the list of connected apps.</li>
                <li>Click on "Remove" to disconnect the app and delete associated data.</li>
                <li>If you want us to delete any stored data, please contact us via email.</li>
            </ol>

            <h3>Changes to this Privacy Policy</h3>
            <p>We may update Our Privacy Policy from time to time. Changes are effective when posted on this page.</p>
            
            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, You can contact us via email at <a href="mailto:nextstockdev@gmail.com">nextstockdev@gmail.com</a>.</p>
        </div>
    );
}
