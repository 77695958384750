import React, { useState, useRef, useEffect } from 'react';
import firebase from '../Firebase/firebaseConnection';
import axios from 'axios';
import './EmailConfirmation.css';

const API_ENDPOINT = "https://moonsigns-backend-0fa96cd0f5d0.herokuapp.com/v1/next-stock/";
// const API_ENDPOINT = "http://localhost:5001/v1/next-stock/";

export default function EmailConfirmation({ setPage }) {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [passwordInput, setPasswordInput] = useState('');
    const passwordRef = useRef(null);

    const handleChange = (index, value) => {
        if (!/^[0-9]?$/.test(value)) return;
        let newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        if (newCode.join('').length === 6) {
            validateCode(newCode.join(''));
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const validateCode = async (enteredCode) => {
        try {
            setError('');
            const userUid = localStorage.getItem('userUid');
            const snapshot = await firebase.database().ref(`Users/${userUid}/accountValidationCode`).once('value');
            const storedCode = snapshot.val();
            const reversedCode = enteredCode.split("").reverse().join("");

            if (String(reversedCode) === String(storedCode)) {
                setMessage('Thank you! Loading...');
                await firebase.database().ref(`Users/${userUid}`).update({
                    accountValidationRequired: false
                });
                setTimeout(() => {
                    setPage('Home');
                }, 2500);
            } else {
                setError('Invalid verification code. Please try again.');
                setCode(['', '', '', '', '', '']);
                inputRefs.current[0].focus();
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    async function sendNewActivationCode() {
        setError('');
        setMessage('Loading...');
        const randomCode = Math.floor(100000 + Math.random() * 900000);
        const userUid = localStorage.getItem('userUid');
        const email = localStorage.getItem('userEmail');
        const name = "Dear Customer";
        try {
            await firebase.database().ref(`Users/${userUid}`).update({
                accountValidationCode: randomCode,
            });

            const response = await axios.post(API_ENDPOINT + 'send-signup-confirmation-email', {
                email:email,
                name:name,
                text:String(randomCode),
                subject:""
            });

            setMessage('');
            console.log('Activation code sent to: '+ email)
            
            if (response.data.status === 'success') {
                alert('Activation code sent to: '+ email);
            } else {
                alert('Sorry. Something went wrong.\n\n Activation email failed.\n\nPlease contact us at nextstockdev@gmail.com.');
                return;
            }
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error: Email sending failed.');
            setMessage('');
        }
    }

    const restartSignupProcess = async (password) => {
        try {
            setMessage('Resetting account...');
            const user = firebase.auth().currentUser;
            const userUid = localStorage.getItem('userUid');
            const email = localStorage.getItem('userEmail');
    
            if (!user || !email) {
                setError("Missing user information.");
                setMessage('');
                return;
            }
    
            // Look at all linked providers for this user
            const providers = user.providerData.map((provider) => provider.providerId);
    
            if (providers.includes('password')) {
                // Re-authenticate with email/password
                if (!password) {
                    setError("Password required to reset account.");
                    setMessage('');
                    return;
                }
    
                const credential = firebase.auth.EmailAuthProvider.credential(email, password);
                try {
                    await user.reauthenticateWithCredential(credential);
                } catch (error) {
                    if (error.code === 'auth/wrong-password') {
                        setError("Incorrect password or account was not created with email/password.");
                    } else {
                        setError("Re-authentication failed. " + error.message);
                    }
                    setMessage('');
                    return;
                }
    
            } else if (providers.includes('google.com')) {
                // Re-authenticate with Google popup
                const provider = new firebase.auth.GoogleAuthProvider();
                try {
                    await user.reauthenticateWithPopup(provider);
                } catch (error) {
                    setError("Google re-authentication failed. " + error.message);
                    setMessage('');
                    return;
                }
    
            } else {
                setError("Unsupported sign-in method. Please contact support.");
                setMessage('');
                return;
            }
    
            // Remove user data
            if (userUid) {
                await firebase.database().ref(`Users/${userUid}`).remove();
            }
    
            // Delete the user from Auth
            await user.delete();
    
            // Clear local storage
            localStorage.clear();
            setPage("Signin");
            window.open("https://next-stock.github.io/app", "_self");
        } catch (error) {
            console.error("Error restarting signup process:", error);
            setError("Failed to reset account. Please try again.");
            setMessage('');
        }
    };
    
    

    useEffect(() => {
        if (showModal && passwordRef.current) {
            passwordRef.current.focus();
        }
    }, [showModal]);
    

    return (
        <div className="email-confirmation-container">
            <h2>Email Verification</h2>
            <p>Please enter the 6-digit code sent to your email.</p>
            <div className="code-inputs">
                {code.map((num, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={num}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className="code-input"
                        autoFocus={index === 0}
                    />
                ))}
            </div>
            {error && <p className="error-message">{error}</p>}
            {message && <p className="loading-message">{message}</p>}
            {!message && <button className="resend-code" onClick={sendNewActivationCode}>Resend Code</button>}

            <p style={{
                color: '#444', 
                textAlign: 'center', 
                maxWidth: '400px', 
                margin: '25px auto',
                fontSize: '10px'
            }}>
                <strong>Note</strong>: If you haven’t received the activation code within one minute, please check your spam folder.
            </p>

            <button onClick={() => setShowModal(true)} className='reset-signup-btn'>
                Start SignUp process again
            </button>

            {showModal && (
                <div className="modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Confirm Password</h3>
                        <p>To reset your account, please enter your password:</p>
                        <input
                            type="password"
                            value={passwordInput}
                            onChange={(e) => setPasswordInput(e.target.value)}
                            placeholder="Enter password"
                            ref={passwordRef}
                        />
                        <div className="modal-reset-signup-actions">
                            <button onClick={() => setShowModal(false)} style={{margin:'5px', marginBlock:'12px', padding:'5px', color:'#444', cursor:'pointer'}}>Cancel</button>
                            <button onClick={() => {
                                restartSignupProcess(passwordInput);
                                setShowModal(false);
                                setPasswordInput('');
                            }} style={{margin:'5px', marginBlock:'12px', padding:'5px', color:'white', backgroundColor:'#ff8282', borderColor:'#ddd', cursor:'pointer'}}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
