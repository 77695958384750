import { useState } from 'react';
import firebase from '../Firebase/firebaseConnection';

export function useSettingsManager() {
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());

    function formatDateToISO(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    function dateDifferenceInDays(startDate, endDate) {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const timeDifference = endDate - startDate;
        return Math.floor(timeDifference / millisecondsPerDay);
    }

    async function getUserFromStorage() {
        const email = localStorage.getItem('userEmail');
        const userUid = localStorage.getItem('userUid');
        const planType = localStorage.getItem('userPlanType');
        setUserId(userUid);
        setUser({ email: email, planType: planType });
    }

    function generateAuthCode() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let authCode = '';
        for (let i = 0; i < 24; i++) {
            authCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return authCode;
    }

    function settingsPagePrdUrl(userId, authCode) {
        return `https://next-stock.github.io/pm/?code=${userId}&authcode=${authCode}`;
    }

    async function handleSettingsPress(definedId) {
        let checkedUserId;
        if (userId) {
            checkedUserId = userId;
        }else if(definedId){
            checkedUserId = definedId;
        }else{
            window.alert("Error: User ID not found. Please log in again.");
            return;
        }

        const authCode = generateAuthCode();
        const authTime = formatDateToISO(currentDate);

        try {
            await firebase.database().ref(`Users/${checkedUserId}`).update({
                authcode: authCode,
                authtime: authTime
            });
            console.log("Data saved successfully");

            const url = settingsPagePrdUrl(checkedUserId, authCode);
            window.open(url, "_self");
        } catch (error) {
            console.error("Error saving data: ", error);
        }
    }

    return { 
        user, 
        userId, 
        getUserFromStorage, 
        generateAuthCode,
        formatDateToISO, 
        dateDifferenceInDays,
        handleSettingsPress
    };
}
