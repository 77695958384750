import React, { useState, useEffect } from "react";
import firebase from "../Firebase/firebaseConnection";
import { Search, Star, XCircle, Archive, Unlock, ArrowUp, ArrowDown  } from "react-feather";
import Target from "./Target";
import Header from "../Static/Header";
import PremiumNextStock from "../img/premium-logo.png";
import logoNextStock from "../img/logo.png";
import { useSettingsManager } from "../Configuration/SettingsManager";
import "./Home.css";

export default function Home({ setPage }) {
  const { user, getUserFromStorage, handleSettingsPress } = useSettingsManager();

  const [targets, setTargets] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredTargets, setFilteredTargets] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [onCodeStatus, setOnCodeStatus] = useState(false);
  const [currentRemovedCodeActive, setCurrentRemovedCodeActive] = useState(false);

  useEffect(() => {
    getUserFromStorage();
    getTargets("CADtarget", "CAD");
    getTargets("UStarget", "US");
  }, []);

  async function getTargets(target, country) {
    try {
      const snapshot = await firebase.database().ref(target).orderByChild("propertyShortName").once("value");
      const dataList = [];
      snapshot.forEach((item) => {
        dataList.push({
          key: item.key,
          calculatedDividend: item.val().propertyCalculatedDividend,
          currentPrice: item.val().propertyCurrentPrice,
          dividendDollar: item.val().propertyDividendDollar,
          dividendPercent: item.val().propertyDividendPercent,
          propertyCalculatedDividend: item.val().propertyCalculatedDividend,
          newPrice: item.val().propertyNewPrice,
          ps: item.val().propertyPS,
          pricePercent: item.val().propertyPricePercent,
          shortName: item.val().propertyShortName,
          symbol: item.val().propertySymbol,
          country: country,
        });
      });
      setTargets((prevArray) => [...prevArray, ...dataList]);
    } catch (error) {
      console.error("Error fetching targets:", error);
    }
  }

  const removeDuplicates = (array) => {
    const seen = new Set();
    return array.filter((item) => {
      const duplicate = seen.has(item.key);
      seen.add(item.key);
      return !duplicate;
    });
  };

  const handleSearch = () => {
    const searchTerm = searchText.trim().toLowerCase();

    if (!searchTerm) {
      setFilteredTargets([]);
      return;
    }

    const exactMatch = targets.find(
      (item) =>
        (typeof item.shortName === "string" && item.shortName.toLowerCase() === searchTerm) ||
        (typeof item.symbol === "string" && item.symbol.toLowerCase() === searchTerm)
    );

    if (exactMatch) {
      setFilteredTargets([exactMatch]);
      return;
    }

    const partialMatches = targets.filter(
      (item) =>
        (typeof item.shortName === "string" && item.shortName.toLowerCase().includes(searchTerm)) ||
        (typeof item.symbol === "string" && item.symbol.toLowerCase().includes(searchTerm))
    );

    setFilteredTargets(partialMatches);
  };

  const handleTextChange = (text) => {
    setSearchText(text);
  };

  const handleTextSubmit = () => {
    if (searchText.length > 2) {
      handleSearch();
    } else {
      alert("Type at least 3 characters to search.");
      setFilteredTargets([]);
    }
  };

  const toggleModal = () => setModalVisible((prev) => !prev);

  const handleSelectedCode = (code) => {
    console.log("Selected Code:", code);
    setSelectedCodes((prevCodes) => [...prevCodes, code]);
  };

  const handleRemoveCode = (code) => {
    setCurrentRemovedCodeActive(code);
    setSelectedCodes((prevCodes) => prevCodes.filter((c) => c !== code));
    toggleOnCodeStatus();
  };

  const handleClearList = () => {
    selectedCodes.forEach((code, index) => {
      setTimeout(() => {
        setCurrentRemovedCodeActive(code);
        toggleOnCodeStatus();
        if (index === selectedCodes.length - 1) {
          setSelectedCodes([]);
        }
      }, index * 100);
    });
  };

  const toggleOnCodeStatus = () => {
    setOnCodeStatus((prev) => !prev);
  };

  return (
    <div className="home-container">
      <Header setPage={setPage} />

      <img src={PremiumNextStock} alt="Premium Logo" className="logo-circle" />
      <p className="logo-your" style={{ marginBottom: "-2px" }}>
        Your
      </p>
      <img src={logoNextStock} alt="Logo" className="logo-text" />

      {user.planType === "free" ? (
        <div className="plan-container">
          <div className="search">
            <Search className="icon" />
            <input type="text" placeholder="BLOCKED" value="BLOCKED" disabled className="search-input" />
          </div>
          <p className="upgrade-text">Upgrade your plan and access over 400 companies target rate.</p>
          <button className="home-upgrade-btn" onClick={handleSettingsPress}>
            <Unlock size={14} /> Upgrade Plan
          </button>
        </div>
      ) : (
        <div className="premium-plan">
          <div className="compare-header">
            <Archive size={15} className="compare-text" style={{marginTop:'-8px'}}/>
            <p onClick={toggleModal} className="compare-text">
                See comparison list ({selectedCodes.length} selected)
            </p>
            {selectedCodes.length > 0 && (
              <button className="clear-btn" onClick={handleClearList}>
                <XCircle size={12} style={{ marginBottom: "-2px" }} /> Clear list
              </button>
            )}
          </div>

          <div className="search">
            <Search className="icon" onClick={handleTextSubmit} />
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={(e) => handleTextChange(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleTextSubmit()}
              className="search-input"
            />
          </div>
        </div>
      )}

      <p className="results-text">📌 {removeDuplicates(filteredTargets).length} result(s)</p>

      <div className="target-list">
        {removeDuplicates(filteredTargets).map((item) => (
          <Target
            key={item.key}
            data={item}
            selectedCodes={selectedCodes}
            onCodeSelected={handleSelectedCode}
            onCodeRemoved={handleRemoveCode}
            onCodeStatusChange={onCodeStatus}
            currentRemovedCodeActive={currentRemovedCodeActive}
          />
        ))}
      </div>

      {isModalVisible && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div style={{ width: '100%', display: 'flex',justifyContent: 'flex-end',  alignItems: 'center'}}>
                <button className="modal-close-btn" onClick={toggleModal}>Close</button>
            </div>
            <h3 style={{marginBlock:'10px'}}>Selected Targets ({selectedCodes.length})</h3>
            <ul className="modal-list">
            {selectedCodes
            .map(code => targets.find(target => target.shortName === code || target.symbol === code))
            .filter(Boolean) // Remove undefined values (in case any selected code is missing in targets)
            .sort((a, b) => (b.pricePercent ?? 0) - (a.pricePercent ?? 0)) // Sort by pricePercent descending
            .map((fullTarget, index) => (
                <li key={fullTarget.key} className={`modal-card ${index === 0 ? "first" : ""} ${index === selectedCodes.length - 1 ? "last" : ""}`}>
                    <h4 style={{paddingRight:'28px'}}>{fullTarget.shortName} ({fullTarget.symbol})</h4>
                    <p style={{fontSize:'13px', marginTop:'8px', marginBottom:'-1px'}}>Target Price: $ {fullTarget.newPrice ?? 'n/a'}</p>
                    <p><strong>Price Target Percentage Change:</strong> {fullTarget.pricePercent ?? 'n/a'} %</p>
                    <p style={{color:'#666', fontSize:'11px', marginTop:'-1px'}}>Last Recorded Price: $ {fullTarget.currentPrice ?? 'n/a'}</p>
                    <button className="remove-btn" onClick={() => handleRemoveCode(fullTarget.shortName)}>❌</button>
                    {(index === 0 || index === selectedCodes.length - 1) && fullTarget.pricePercent > 0  ? <><ArrowUp color="green" size={18} /> 📈</> : null}
                    {(index === 0 || index === selectedCodes.length - 1) && fullTarget.pricePercent < 0  ? <><ArrowDown color="red" size={18} /> 📉</> : null}
                    {/* {index === selectedCodes.length - 1 && fullTarget.pricePercent < 0  ? <ArrowDown color="red" size={18} /> : null} */}
                </li>
            ))}

            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
