import React, { useState, useEffect } from 'react';
import { Home, ArrowUpCircle, Youtube, Settings, DollarSign, LogOut, BookOpen } from 'react-feather';
import { useSettingsManager } from '../Configuration/SettingsManager';
import logoNextStock from '../img/Next Stock.png';
import './HeaderCSS.css';


export default function Header({ setPage }) {
    const { user, getUserFromStorage, handleSettingsPress } = useSettingsManager();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth / 1.5);
    
    useEffect(() => {
        getUserFromStorage();
    }, []);

    useEffect(() => {
            const handleResize = () => {
                setScreenWidth(window.innerWidth / 1.5);
            };
    
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [window.innerWidth]);

    const handleLogout = () => {
        localStorage.clear();
        setPage("Signin");
    };

    return (
        <div className="header">
            {/* LEFT SIDE - Logo and Navigation */}
            <div className="header-left">
                
            <div className="logo-container">
                <img 
                    src={logoNextStock} 
                    alt="Next Stock" 
                    className="logo-next-stock"
                    onClick={() => setPage("Home")} 
                />
            </div>

            <div className="menu-item" onClick={() => setPage("Home")}>
                <Home size={29} className="menu-icon" />
                <span className="menu-text">Home</span>
            </div>

                <div className="menu-item" onClick={() => setPage("Top10")}>
                    <ArrowUpCircle size={29} className="menu-icon" />
                    <span className="menu-text">TOP10</span>
                </div>

                <div className="menu-item" onClick={() => setPage("Portfolio")}>
                    <BookOpen size={29} className="menu-icon" />
                    <span className="menu-text">Portfolio</span>
                </div>

                <div className="menu-item" onClick={() => setPage("Youtube")}>
                    <Youtube size={29} className="menu-icon" />
                    <span className="menu-text">Channel</span>
                </div>

                <div className="menu-item" onClick={handleSettingsPress}>
                    <DollarSign size={29} className="menu-icon2" />
                    <span className="menu-text2">Subscription</span>
                </div>

                <div className="menu-item" onClick={() => setPage("Settings")}>
                    <Settings size={29} className="menu-icon" />
                    <span className="menu-text">Settings</span>
                </div>
            </div>

            {/* RIGHT SIDE - User Info & Logout */}
            <div className="header-right">
                {screenWidth > 400 ? 
                <>
                    <span className="menu-text" style={{fontSize:'12px'}}>{user.email} </span>
                    <div className="menu-item" onClick={handleLogout}>
                        <LogOut className="menu-icon logout-icon" />
                        <span className="menu-text">Logout</span>
                    </div>
                </>
                : 
                    <div className="menu-item" onClick={handleLogout}>
                        <LogOut className="menu-icon logout-icon" size={20}  />
                        {/* <span className="menu-text">Logout</span> */}
                    </div>
                }

            </div>
        </div>
    );
}
