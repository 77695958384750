import React, { useState, useEffect } from 'react';
import { Unlock } from 'react-feather';
import { useSettingsManager } from '../Configuration/SettingsManager';
import logoNextStock from '../img/Next Stock.png';
import Header from '../Static/Header';
import './Portfolio.css'; // Reuse styling

export default function Portfolio({ setPage }) {
    const { user, getUserFromStorage, handleSettingsPress } = useSettingsManager();
    const [loading, setLoading] = useState(true);
    const [isTrialPeriod, setIsTrialPeriod] = useState(false);
    const [userPlanTypeFromStorage, setUserPlanTypeFromStorage] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedPortfolio, setSelectedPortfolio] = useState('');

    useEffect(() => {
        async function init() {
            const planTypeFromStorage = localStorage.getItem('userPlanType');
            setUserPlanTypeFromStorage(planTypeFromStorage || '');
            const isUserTrialPeriod = localStorage.getItem('userTrial') === 'true';
            if (isTrialPeriod !== isUserTrialPeriod) {
                setIsTrialPeriod(isUserTrialPeriod);
            }
            getUserFromStorage();
            setLoading(false);
        }
        init();
    }, []);

    const handlePages = (page) => {
        setPage(page);
    };

    const handleSelectionChange = (country, portfolio) => {
        if (country) setSelectedCountry(country);
        if (portfolio) setSelectedPortfolio(portfolio);
    };

    return (
        <div className="top10-container">
            <Header setPage={handlePages} />
            <div className="top10-content">
                {loading ? (
                    <div className="top10-loading">
                        <div className="top10-spinner"></div>
                    </div>
                ) : (
                    <div className="top10-box">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={logoNextStock} alt="Next" className="top10-logo-next-stock" />
                            <h2 className="porfifolio-title">PORTFOLIO</h2>
                            <img src={logoNextStock} alt="Next" className="top10-logo-next-stock" />
                        </div>

                        <p style={{ margin: "-5px" }}><strong>USA & CANADA</strong></p>

                        {/* TO BE UPDATED */}
                        {/* {(userPlanTypeFromStorage.toLowerCase() === 'premium' && isTrialPeriod) ? 
                            <p style={{marginBottom:"30px", fontSize:'12px'}}>Plan: <strong>FREE TRIAL <p>|</p> <p style={{fontSize:'10px', color:'#777', fontWeight:'500'}}>Upgrade your plan to access Next Stock's model portfolios!</p>{isTrialPeriod}</strong></p>
                        :
                        <> */}
                            <p style={{marginBottom:"30px", fontSize:'12px'}}>Plan: <strong>{userPlanTypeFromStorage.toUpperCase()}</strong></p>
                            <p style={{ marginBottom: "20px", fontSize: '14px', fontStyle: 'italic' }}>
                                📌 Lower-tier portfolios prioritize safer, value-based stocks, while higher-tier portfolios offer a more balanced mix of U.S. and Canadian equities.
                            </p>
                        {/* </>
                        } */}

                        {/* TO BE UPDATED */}
                        {/* {userPlanTypeFromStorage.toLowerCase() === 'premium' && !isTrialPeriod ? ( */}
                        {userPlanTypeFromStorage.toLowerCase() === 'premium' ? (
                            <div className="porfifolio-selectors">
                                {/* <select onChange={(e) => handleSelectionChange(e.target.value, selectedPortfolio)}>
                                    <option value="">Select Stock Market</option>
                                    <option value="CANADA">CANADA</option>
                                    <option value="USA">USA</option>
                                </select> */}

                                <select onChange={(e) => handleSelectionChange(selectedCountry, e.target.value)}>
                                    <option value="">Select Portfolio Value</option>
                                    <option value="5K USD / 7K CAD">5K USD / 7K CAD</option>
                                    <option value="10K USD / 14K CAD">10K USD / 14K CAD</option>
                                    <option value="25K USD / 35K CAD">25K USD / 35K CAD</option>
                                    <option value="50K USD / 70K CAD">50K USD / 70K CAD</option>
                                    <option value="100K USD / 140K CAD">100K USD / 140K CAD</option>
                                    <option value="250K USD / 350K CAD">250K USD / 350K CAD</option>
                                    <option value="500K USD / 700K CAD">500K USD / 700K CAD</option>
                                    <option value="1M USD / 1.4M CAD">1M USD / 1.4M CAD</option>
                                    <option value="2.5M USD / 3.5M CAD">2.5M USD / 3.5M CAD</option>
                                    <option value="5M USD / 7M CAD">5M USD / 7M CAD</option>
                                </select>
                            </div>
                        ) : (
                            <button className="top10-upgrade-btn" onClick={handleSettingsPress}>
                                <Unlock /> Upgrade Plan
                            </button>
                        )}

                        {userPlanTypeFromStorage.toLowerCase() === 'premium' && selectedPortfolio && (
                            <div className="top10-list-container">
                                <p style={{ fontSize: '16px', marginTop: '30px', textAlign: 'center' }}>
                                    ⚠️ Portfolio for <strong>{selectedPortfolio}</strong> is under construction.
                                    <br />
                                    Please check back soon!
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
