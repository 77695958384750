import React, { useState, useEffect, useRef } from 'react';
import firebase from '../Firebase/firebaseConnection';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Eye, EyeOff } from 'react-feather';
import logoNextStock from '../img/Next Stock.png';
import ggoogleIcon from '../img/Ggoogle-icon.jpg';
import facebookIcon from '../img/facebook-icon.png';
import './Signin.css';

export default function Signin({ setPage }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showEmailPasswordLogin, setShowEmailPasswordLogin] = useState(false);
    const [loading, setLoading] = useState(true);

    const planCheckedRef = useRef(false); // ✅ Prevents duplicate execution

    useEffect(() => {
        async function loadStorage() {
            const urlParams = new URLSearchParams(window.location.search);
            const userUid = urlParams.get('code') || localStorage.getItem('userUid');
            if (userUid) {
                const snapshot = await firebase.database().ref('Users').child(userUid).once('value');
                const userDb = snapshot.val();
                if (userDb) {
                    if (userDb.accountValidationRequired && userDb.accountValidationCode) {
                        setPage("AccountConfirmation");
                        return;
                    }

                    localStorage.setItem('userUid', userUid);
                    localStorage.setItem('userEmail', userDb.email);
                    localStorage.setItem('userStatus', userDb.status);
                    localStorage.setItem('userTrial', userDb?.plan?.trial ?? false);
                    await checkUserPlanOnDbAndLoadHome(userUid, userDb);
                }
            }
            setLoading(false);
        }

        async function checkRedirectResult() {
            try {
                const result = await firebase.auth().getRedirectResult();
                if (result.user) {
                    console.log("✅ Redirect login successful.");
                    await handleFacebookResult(result);
                }
            } catch (error) {
                console.error("❌ Error handling redirect result:", error.message);
            }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const currentPageSet = urlParams.get('page');
        if (currentPageSet && currentPageSet === 'ns-privacy-policy') {
            setPage("PrivacyPolicy");
            return;
        }

        checkRedirectResult();

        loadStorage();
    }, []);

    function formatDate(date) {
        return date.toISOString().slice(0, 19); // Format as YYYY-MM-DDTHH:mm:ss
    }

    function addOneMonthAndFormatDate(date) {
        let nextDate = new Date(date);
        nextDate.setMonth(nextDate.getMonth() + 1);
        return formatDate(nextDate);
    }

    async function checkUserPlanOnDbAndLoadHome(uid, userDb) {
        if (planCheckedRef.current) return; // ✅ Prevents duplicate execution
        planCheckedRef.current = true; // ✅ Mark as checked

        localStorage.setItem('userUid', uid);
        localStorage.setItem('userEmail', userDb.email);
        localStorage.setItem('userStatus', userDb.status);
        localStorage.setItem('userTrial', userDb?.plan?.trial ?? false);

        const urlParams = new URLSearchParams(window.location.search);
        const currentPageSet = urlParams.get('page');
        const validPages = ['Signin', 'Signup', 'Home', 'Top10', 'Youtube', 'Settings', 'Contact', 'Logout', 'ns-privacy-policy', 'Portfolio'];
        const pageToLoad = validPages.includes(currentPageSet) ? currentPageSet : 'Home';

        if (pageToLoad === 'Logout') {
            localStorage.clear();
            setLoading(false);
            setPage("Signin");
            window.open("https://next-stock.github.io/app", "_self");
            return;
        }


        const startDate = new Date();
        const endDate = new Date(userDb.plan.expire);
        const differenceInDays = dateDifferenceInDays(startDate, endDate);

        if (userDb.plan.type !== "free" && differenceInDays < 0) {
            localStorage.setItem('userPlanType', "free");
            const planUpdated = await updateUserPlanDbToFree(uid);
            if (planUpdated) {
                setEmail('');
                setPassword('');
                setLoading(false);
                
                setTimeout(() => { // ✅ Ensures the alert does not interfere with state updates
                    alert('Your premium plan has expired.');
                    setPage(pageToLoad);
                }, 100);
            }
            return;
        }

        localStorage.setItem('userPlanType', userDb.plan.type);
        setEmail('');
        setPassword('');
        setPage(pageToLoad);
    }

    async function updateUserPlanDbToFree(userUid) {
        try {
            await firebase.database().ref(`Users/${userUid}/plan`).update({
                type: "free"  // ✅ Correct update syntax
            });
            return true;
        } catch (error) {
            console.error("Error updating user plan:", error);
            return false;
        }
    }

    async function login() {
        try {
            const value = await firebase.auth().signInWithEmailAndPassword(email, password);
            saveUserOnLocalStorage(value.user.uid);

        } catch (error) {
            alert('Invalid email or password. \n' + error);
            setPassword('');
        }
    }

    async function signInWithGoogle() {
        const provider = new firebase.auth.GoogleAuthProvider();
    
        try {
            // Sign in with Google
            const result = await firebase.auth().signInWithPopup(provider);
            const user = result.user;

            const userRef = firebase.database().ref('Users').child(user.uid);
            const snapshot = await userRef.once('value');
    
            if (!snapshot.exists()) {
                await createNewUserInDb(userRef, user);
            }
            // Store user data in local storage
            // localStorage.setItem('userUid', user.uid);
            // localStorage.setItem('userEmail', user.email);
            saveUserOnLocalStorage(user.uid);
            // setPage("Home");
        } catch (error) {
            alert("Google sign-in failed: " + error.message);
        }
    }

    async function signInWithFacebook() {
        const provider = new firebase.auth.FacebookAuthProvider();
    
        try {
            const result = await firebase.auth().signInWithPopup(provider);
            await handleFacebookResult(result);
        } catch (error) {
            if (error.code === 'auth/popup-blocked' || error.message.includes('popup')) {
                console.warn("Popup blocked. Trying redirect instead...");
                await firebase.auth().signInWithRedirect(provider);
            } else {
                console.error("❌ Facebook sign-in failed:", error.message);
                alert(`❌ Facebook sign-in failed: ${error.message}`);
            }
        }
    }

    async function handleFacebookResult(result) {
        try{
            const user = result.user;
            const userRef = firebase.database().ref('Users').child(user.uid);
            const snapshot = await userRef.once('value');
        
            // Create user in DB if not exists
            if (!snapshot.exists()) {
                await createNewUserInDb(userRef, user);
            }
        
            // Save to local storage and proceed
            saveUserOnLocalStorage(user.uid);
        } catch (error) {
            alert("Sorry! Sign-in has failed: " + error.message);
        }
    
    }
    

    async function createNewUserInDb(userRef, user) {
        const creationTypeUserPlan = "premium";
        const creationTypeUserStatus = "member";
        
        const formattedStartDate = formatDate(new Date());
        const formattedEndDate = addOneMonthAndFormatDate(new Date());

        await userRef.set({
            name: user.displayName,
            email: user.email,
            authcode: "",
            authtime: "",
            status: creationTypeUserStatus,
            plan: {
                type: creationTypeUserPlan,
                expire: formattedEndDate,
                start: formattedStartDate,
                collection_method: "",
                id: "",
                invoice_pdf: "",
                paid: "",
                period: { end: 0, start: 0 },
                product: "",
                subscription_id: "",
                subscription_canceled: "",
                subscription_status: "",
                subscription_cancelation_date: "",
                currency: "",
                payment_failed: "",
                payment_failed_date: "",
                payment_failed_verified: "",
                payment_failed_link: "",
                trial: true
            },
            stripeCustomerId: ""
        });

    }

    async function saveUserOnLocalStorage(uid) {
        const snapshot = await firebase.database().ref('Users').child(uid).once('value');
        const userDb = snapshot.val();
        
        if (userDb) {
            localStorage.setItem('userUid', uid);
            localStorage.setItem('userEmail', userDb.email);
            localStorage.setItem('userStatus', userDb.status);
            localStorage.setItem('userTrial', userDb?.plan?.trial ?? false);
            if (userDb.accountValidationRequired === true && userDb.accountValidationCode) {
                setPage("AccountConfirmation");
                return;
            }
            await checkUserPlanOnDbAndLoadHome(uid, userDb);
        }
    }

    function dateDifferenceInDays(startDate, endDate) {
        return Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));
    }

    async function ResetPasswordEmail() {
        if (email.length < 3 || !email.includes("@")) {
            alert("Invalid email.");
            return;
        }
        try {
            await firebase.auth().sendPasswordResetEmail(email);
            alert(`Reset password email sent to ${email}. Check your inbox or spam folder.`);
        } catch (error) {
            alert("Ops, something went wrong.\n\n" + error);
        }
    }

    return (
        <div className="container">
            {loading ? (
                <div className="loading">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="content">
                    <div className="overlay">
                        <div className="login-box">
                            <img src={logoNextStock} alt="Logo" className="logo" />

                            <button className="google-btn" onClick={signInWithGoogle}>
                                <div> <img src={ggoogleIcon} alt="Logo" className="google-logo" /></div>
                                <div>Log in with <strong>Google</strong></div>
                            </button>

                            <button className="facebook-btn" onClick={signInWithFacebook}>
                                <div><img src={facebookIcon} alt="Logo" className="facebook-logo" /></div>
                                <div>Log in with <strong> Facebook</strong></div>
                            </button> 


                            {showEmailPasswordLogin?
                                <>
                                    <div onClick={() =>setShowEmailPasswordLogin(!showEmailPasswordLogin)}> <label  style={{fontSize:'9px', cursor:'pointer'}}>Hide section</label> </div>
                                    <div className="title-section"> <label>Email</label> </div>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                                    <div className="title-section"> <label>Password</label> </div>
                                    <div className="password-container">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && login()}
                                        />
                                        <button className="toggle-password" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                        </button>
                                    </div>

                                    <button className="login-btn" onClick={login}>Log in</button>
                                    <p style={{color:'#444', fontSize:'9px', marginTop:'20px'}}>Forgot your password? <span onClick={ResetPasswordEmail} className='bottom-texts'>Reset</span></p>


                                    <div className="links">
                                        <p style={{color:'#444', fontSize:'11px', marginTop:'-20px'}}>Create an account? <span onClick={() => setPage("Signup")} className='bottom-texts'>Sign up</span></p>
                                        {/* <p style={{color:'#444', fontSize:'11px', marginTop:'-4px'}}><span onClick={() => setPage("Settings")} style={{ marginLeft: '0px', fontSize: '11px' }}>Contact us</span></p> */}
                                    </div>
                                </>
                            :
                            <>
                                    <div  onClick={() =>setShowEmailPasswordLogin(!showEmailPasswordLogin)}> <label style={{fontSize:'9px', cursor:'pointer'}}>Login with Email & Password</label> </div>
                                </>
                            }
                            
                            <hr style={{display:'flex', width:'90%', fontSize:'11px', marginTop:'45px', marginBottom:'0px'}}></hr>
                            <p style={{fontSize:'11px', marginTop:'40px', marginBottom:'0px'}}>Developed by <strong>OTANER TECH INC.</strong></p>
                            <p style={{color:'#444', marginTop:'0px', marginBottom:'0px'}}><span onClick={() => setPage("Settings")} style={{ marginLeft: '0px', fontSize: '9px', cursor:'pointer' }}>Contact us</span></p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
