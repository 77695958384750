import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebaseConnection';
import './Video.css';

export default function Video({ data }) {
    const [user, setUser] = useState({});

    const [width, setWidth] = useState(window.innerWidth / 1.5);
    const height = (width * 9) / 16; // Maintain aspect ratio

    useEffect(() => {
        const email = localStorage.getItem('userEmail');
        const status = localStorage.getItem('userStatus');
        if (email && status) {
            setUser({ email: email, status: status });
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth / 1.5);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    async function deleteVideo() {
        await firebase.database().ref('Youtube').child(data.key).remove();
    }

    return (
        <div className="video-container">
            {user.status === 'admin' && (
                <button className="delete-btn" onClick={deleteVideo}>Remove</button>
            )}
            <iframe
                width={width}
                height={height}
                src={data.link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
}
