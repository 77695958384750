import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebaseConnection';
import Header from '../Static/Header';
import Video from './Video';
import './Youtube.css';

export default function Youtube({ setPage }) {
    const [videos, setVideos] = useState([]);
    const [user, setUser] = useState({});
    const [newLink, setNewLink] = useState('');

    useEffect(() => {
        async function getVideos() {
            firebase.database().ref("Youtube").on('value', (snapshot) => {
                const videoList = [];
                snapshot.forEach((item) => {
                    videoList.push({ key: item.key, link: item.val().link });
                });
                setVideos([...videoList].reverse());
                // setVideos(videoList);
            });
        }

        function getUserEmailFromStorage() {
            const email = localStorage.getItem('userEmail');
            const status = localStorage.getItem('userStatus');
            if (email && status) {
                setUser({ email: email, status: status });
            }
        }

        getVideos();
        getUserEmailFromStorage();
    }, []);

    async function registerVideo() {
        if (newLink.includes("/embed/")) {
            const trimmedLink = newLink.trim().replace('"', '');
            const newVideo = firebase.database().ref('Youtube').push();
            newVideo.set({ link: trimmedLink });

            setNewLink('');
        } else {
            alert('Only "embed" links will be accepted:\n\n1. Go to the video (URL) on YouTube.\n2. Click "Share" below the video.\n3. Click "Embed" < >\n4. Copy the "src" link\n5. Paste and ADD.\n6. The video should be loaded automatically.');
        }
    }

    return (
        <div className="youtube-container">
            <Header setPage={setPage} />

            {user.status === 'admin' && (
                <div className="new-video">
                    <label>LINK</label>
                    <input
                        type="text"
                        value={newLink}
                        onChange={(e) => setNewLink(e.target.value)}
                    />
                    <button className="add-btn" onClick={registerVideo}>ADD</button>
                </div>
            )}

            <div className="video-list">
                {videos.map((item) => (
                    <Video key={item.key} data={item} />
                ))}
            </div>
        </div>
    );
}
