import React, { useState } from 'react';
import Home from '../src/Pages/Home';
import Youtube from '../src/Pages/Youtube';
import Settings from './Pages/Settings';
import Top10 from '../src/Pages/Top10';
import Signin from '../src/Pages/Signin';
import Signup from '../src/Pages/Signup';
import PrivacyPolicy from './Pages/PrivacyPolicyPage';
import EmailConfirmation from './Pages/EmailConfirmation';
import Portfolio from './Pages/Portfolio';

const validPages = ['Signin', 'Signup', 'Home', 'Top10', 'Youtube', 'Settings', 'Settings', 'PrivacyPolicy', 'AccountConfirmation', 'Portfolio'];

export default function App() {
  const [currentPage, setCurrentPage] = useState('Signin');

  const renderPage = () => {
    switch (currentPage) {
      case 'Signin':
        return <Signin setPage={handlePages} />;
      case 'Signup':
        return <Signup setPage={handlePages} />;
      case 'Home':
        return <Home setPage={handlePages} />;
      case 'Portfolio':
        return <Portfolio setPage={handlePages} />;
      case 'Youtube':
        return <Youtube setPage={handlePages} />;
      case 'Settings':
        return <Settings setPage={handlePages} />;
      case 'Top10':
        return <Top10 setPage={handlePages} />;
      case 'AccountConfirmation':
        return <EmailConfirmation setPage={handlePages} />;
      case 'PrivacyPolicy':
        return <PrivacyPolicy setPage={handlePages} />;
      default:
        return <Home setPage={handlePages} />; // Fallback to Home if the page is invalid
    }
  };

  const handlePages = (page) => {
    if (validPages.includes(page)) {
      setCurrentPage(page);
    } else {
      setCurrentPage('Home');
    }
  };

  return <div className="app-container">{renderPage()}</div>;
}
