import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebaseConnection';
import { Unlock } from 'react-feather';
import axios from 'axios';
import { useSettingsManager } from '../Configuration/SettingsManager';
import logoNextStock from '../img/Next Stock.png';
import HSBC from '../img/targets/HSBC.png'
import WBA from '../img/targets/WBA.png'
import Header from '../Static/Header';
import './Top10.css';

const API_ENDPOINT = "https://moonsigns-backend-0fa96cd0f5d0.herokuapp.com/v1/next-stock/";

export default function Top10({ setPage }) {
    const { getUserFromStorage, handleSettingsPress } = useSettingsManager();
    const [loading, setLoading] = useState(true);
    const [userPlanTypeFromStorage, setUserPlanTypeFromStorage] = useState('');
    const [isTrialPeriod, setIsTrialPeriod] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedList, setSelectedList] = useState('');
    const [targets, setTargets] = useState([]);

    useEffect(() => {
        async function getPlan() {
            const planTypeFromStorage = localStorage.getItem('userPlanType');
            setUserPlanTypeFromStorage(planTypeFromStorage || '');
            const isUserTrialPeriod = localStorage.getItem('userTrial') === 'true';
            if (isTrialPeriod !== isUserTrialPeriod) {
                setIsTrialPeriod(isUserTrialPeriod);
            }
        }
        getPlan();
        getUserFromStorage();
        setLoading(false);
    }, [isTrialPeriod]);

    const handlePages = (page) => {
        setPage(page);
    };

    const handleSelectionChange = (country, list) => {
        if (!country && !list) return;

        if (country) setSelectedCountry(country);
        if (list) setSelectedList(list);

        if (country && list) {
            const firebaseOptions = {
                CANADA: { Attractive: 'CADattractive', Growth: 'CADgrowth', Dividend: 'CADdividend', Value: 'CADvalue', Pricey: 'CADpricey' },
                USA: { Attractive: 'USattractive', Growth: 'USgrowth', Dividend: 'USdividend', Value: 'USvalue', Pricey: 'USpricey' },
            };

            const firebaseList = firebaseOptions[country]?.[list] || null;

            if (firebaseList) {
                list === 'Value' ? fetchTargetsForValueList(firebaseList, country) : fetchTargets(firebaseList, country);
            }
        }
    };

    const fetchTargets = async (target, country) => {
        try {
            const snapshot = await firebase.database().ref(target).once('value');
            const dataList = [];

            snapshot.forEach((item) => {
                dataList.push({
                    key: item.key,
                    calculatedDividend: parseFloat(item.val().propertyCalculatedDividend).toFixed(2) || 0,
                    currentPrice: parseFloat(item.val().propertyCurrentPrice).toFixed(2) || 0,
                    dividendDollar: parseFloat(item.val().propertyDividendDollar) || 0,
                    dividendPercent: parseFloat(item.val().propertyDividendPercent) || 0,
                    growth: parseFloat(item.val().propertyGrowth).toFixed(2) || 0,
                    newPrice: parseFloat(item.val().propertyNewPrice).toFixed(2) || 0,
                    ps: parseFloat(item.val().propertyPS).toFixed(2) || 0,
                    pricePercent: parseFloat(item.val().propertyPricePercent).toFixed(2) || 0,
                    shortName: item.val().propertyShortName || 'Unknown',
                    symbol: item.val().propertySymbol || 'Unknown',
                    country: country,
                });
            });

            if (!['CADpricey', 'USpricey', 'CADvalue', 'USvalue'].includes(target)) {
                dataList.sort((a, b) => parseInt(b.key, 10) - parseInt(a.key, 10));
            }
            setTargets(dataList);
        } catch (error) {
            console.error('Error fetching targets:', error);
        }
    };

    const fetchTargetsForValueList = async (target, country) => {
        try {
            const snapshot = await firebase.database().ref(target).once('value');
            const dataList = [];

            snapshot.forEach((item) => {
                dataList.push({
                    key: item.key,
                    propertyTrailingPE: parseFloat(item.val().propertyTrailingPE).toFixed(2) || 0,
                    propertyMarketCap: parseFloat(item.val().propertyMarketCap) || 0,
                    shortName: item.val().propertyShortName || 'Unknown',
                    symbol: item.val().propertySymbol || 'Unknown',
                    country: country,
                });
            });

            setTargets(dataList);
        } catch (error) {
            console.error('Error fetching targets:', error);
        }
    };

    return (
        <div className="top10-container">
            <Header setPage={handlePages} />
            <div className="top10-content">

                {loading ? (
                    <div className="top10-loading">
                        <div className="top10-spinner"></div>
                    </div>
                ) : (
                    <div className="top10-box">
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <img src={logoNextStock} alt="Next" className="top10-logo-next-stock"/>
                            <h2 className='top10-title '>TOP 10</h2>
                            <img src={logoNextStock} alt="Next" className="top10-logo-next-stock"/>

                        </div>
                        <p style={{margin:"-5px"}}> <strong>USA & CANADA</strong></p>
                        {(userPlanTypeFromStorage.toLowerCase() === 'premium' && isTrialPeriod) ?
                            <p style={{marginBottom:"30px", fontSize:'12px'}}>Plan: <strong>FREE TRIAL <p>|</p> <p style={{fontSize:'10px', color:'#777', fontWeight:'500'}}>Upgrade your plan to access Next Stock's TOP 10 Targets!</p></strong></p>
                        :
                            <p style={{marginBottom:"30px", fontSize:'12px'}}>Plan: <strong>{userPlanTypeFromStorage.toUpperCase()}</strong></p>
                        }

                        {(userPlanTypeFromStorage.toLowerCase() === 'premium' && !isTrialPeriod) ? (
                            <div className="top10-selectors">
                                <select onChange={(e) => handleSelectionChange(e.target.value, selectedList)}>
                                    <option value="">Select a Country</option>
                                    <option value="CANADA">CANADA</option>
                                    <option value="USA">USA</option>
                                </select>

                                <select onChange={(e) => handleSelectionChange(selectedCountry, e.target.value)}>
                                    <option value="">Select a List</option>
                                    <option value="Attractive">Attractive</option>
                                    <option value="Growth">Growth</option>
                                    <option value="Dividend">Dividend</option>
                                    <option value="Value">Value</option>
                                    <option value="Pricey">Pricey</option>
                                </select>
                            </div>
                        ) : (
                            <button className="top10-upgrade-btn" onClick={handleSettingsPress}>
                                <Unlock /> Upgrade Plan
                            </button>
                        )}
                        {userPlanTypeFromStorage.toLowerCase() === 'premium' && !isTrialPeriod && ( 
                            <h3>📈 Targets List ({selectedCountry || ' ... '}/{selectedList || ' ... '})</h3> 
                        )}
                        {userPlanTypeFromStorage.toLowerCase() === 'premium' && !isTrialPeriod && (
                            <div className="top10-list-container">
                                    <ul>
                                        {targets.map((item, index) => (
                                            <div className="top10-cards-area">
                                                <li key={item.key} style={{ flex: 1 }}>
                                                    <h4 style={{marginTop:"4px"}}>{index + 1}. {item.shortName} ({item.symbol})</h4>
                                                    {selectedList !== 'Value' && <p className='top10-target-text-one'>Current Price: ${item.currentPrice}</p>}
                                                    {selectedList !== 'Value' && <p className='top10-target-text-one'>Price Target: ${item.newPrice}</p>}
                                                    {selectedList === 'Attractive' && <p className='top10-target-text-two'>Percent Upside: {item.pricePercent}%</p>}
                                                    {selectedList === 'Growth' && <p className='top10-target-text-two'>Growth: {item.growth}%</p>}
                                                    {selectedList === 'Dividend' && <p className='top10-target-text-two'>Dollar Dividend: ${item.dividendDollar}</p>}
                                                    {selectedList === 'Dividend' && <p className='top10-target-text-two'>Percent Dividend: {parseFloat(item.dividendPercent * 100).toFixed(1)}%</p>}
                                                    {selectedList === 'Pricey' && <p className='top10-target-text-two'>Percent Downside: {item.pricePercent}%</p>}
                                                    {selectedList === 'Value' && <p className='top10-target-text-one'>Market Cap: ${parseFloat(item.propertyMarketCap / 1000000000).toFixed(2)} B</p>}
                                                    {selectedList === 'Value' && <p className='top10-target-text-one'>Price/Earnings: {item.propertyTrailingPE}</p>}
                                                </li>
                                                {/* {item.symbol === "WBA" ? <img src={WBA}  alt={item.symbol} style={{width:'125px', height:'auto', marginLeft:'5px', marginTop:'5px'}} /> : <img src={HSBC}  alt={item.symbol} style={{width:'115px', height:'auto', marginLeft:'5px', marginTop:'5px'}} />} */}
                                            </div>
                                        ))}
                                    </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
