import React, { useState, useEffect } from 'react';
import axios from 'axios';
import firebase from '../Firebase/firebaseConnection';
import { useSettingsManager } from '../Configuration/SettingsManager';
import { LogIn } from 'react-feather';
import Header from '../Static/Header';
import logoNextStock from '../img/Next Stock.png';
import './Settings.css';

const API_ENDPOINT = "https://moonsigns-backend-0fa96cd0f5d0.herokuapp.com/v1/next-stock/";

export default function Contact({ setPage }) {

    const { handleSettingsPress } = useSettingsManager();

    const [recipientEmail, setRecipientEmail] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [nameId, setNameId] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const userId = localStorage.getItem('userUid');
        const email = localStorage.getItem('userEmail');
        if (userId && email) {
            setRecipientEmail(email);
            setNameId(userId);
            setLoggedIn(true);
        }
    }, []);

    async function ResetPasswordEmail() {
        if (!recipientEmail || !recipientEmail.includes("@")) {
            alert("Please enter a valid email address.");
            return;
        }
    
        // Confirmation alert before proceeding
        const isConfirmed = window.confirm(
            `Reset password?\n\nAre you sure you want to reset your password for:\n\n${recipientEmail}`
        );
    
        if (!isConfirmed) {
            return; // Stop execution if the user cancels
        }
    
        try {
            await firebase.auth().sendPasswordResetEmail(recipientEmail);
            alert("- Reset password email successfully sent to \n\n" + recipientEmail + "\n\n- Wait for a few seconds...\n- If you don't find it in your inbox, please check the Spam folder.");
        } catch (error) {
            alert("Oops, something went wrong.\n\n" + error.message);
            console.log(error);
        }
    };
    

    async function sendMessage() {
        if (emailSubject.length < 3 || emailMessage.length < 3 || recipientEmail.length < 2 || !recipientEmail.includes('@')) {
            alert('All fields must be filled out.\n\nIf they contain valid data, please revise your email.');
            return;
        }
        try {
            const response = await axios.post(API_ENDPOINT + 'send-email-from-app', {
                name: nameId,
                email: recipientEmail,
                subject: emailSubject,
                text: emailMessage
            });

            if (response.data.status === 'succeed') {
                setRecipientEmail('');
                setEmailSubject('');
                setNameId('');
                setEmailMessage('');
                setSuccess(true);
            } else {
                alert('Sorry. Something went wrong.\n\nEmail sending failed.\n\nPlease contact us at nextstockdev@gmail.com.');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error: Email sending failed.');
        }
    }

    return (
        <div className="contact-container">
            {loggedIn || nameId.length > 2 ? (
                <div className="contact-content">
                    <Header setPage={setPage} />
                    <div className="contact-card">
                        <div className="contact-header">
                            <div style={{width:"20%"}}>
                                <img src={logoNextStock} alt="Next Stock Logo" className="logo_contact" />
                            </div>
                            <div style={{width:"50%"}}>
                                <h3 className="title-text">Settings</h3>
                            </div>
                            <div style={{width:"50%", justifyItems:"flex-end", alignItems:"center", marginTop:'15px'}}>
                                <p className="user-info">✅ Your identification: </p>
                                <span>{recipientEmail}</span>
                            </div>
                        </div>
                        <p className="manage-subscription" onClick={()=>handleSettingsPress(nameId)}>🛠️ Manage your subscription</p>
                        <p className="reset-password" onClick={ResetPasswordEmail}>✏️ Reset your password</p>
                    </div>
                </div>
            ) : (
                <div className="contact-card">
                    <div className="contact-header" style={{display: 'flex', justifyContent:'space-between', alignItems:'center', height: '50px', padding: '30px 5px'}}>
                        <img src={logoNextStock} alt="Next Stock Logo" className="logo_contact" style={{marginTop:'-55px', marginRight:'-100px'}} />
                        <h2 style={{fontSize:'20px'}} >Contact Us</h2>
                        <button className="signin-btn" style={{marginTop:'-10px', fontSize:'8px', flexDirection:'column'}} onClick={() => setPage("Signin")}>
                            <LogIn /> Sign In
                        </button>
                    </div>
                    <label>Your email:</label>
                    <input
                        type="email"
                        value={recipientEmail}
                        onChange={(e) => setRecipientEmail(e.target.value)}
                        placeholder="Enter your email"
                    />
                </div>
            )}

            <div className="contact-card">
                <label>Contact Us:</label>
                <input
                    type="text"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    placeholder="Enter subject"
                    className='input-subject'
                />

                <label>Message:</label>
                <textarea
                    value={emailMessage}
                    onChange={(e) => setEmailMessage(e.target.value)}
                    placeholder="Type your message..."
                ></textarea>

                <button className="send-btn" onClick={sendMessage}>Send</button>

                {success ? (
                    <div className="success-message">
                        <h3>Thank you!</h3>
                        <p>Email successfully sent.</p>
                        <p>We will reach out soon.</p>
                        <p>@Next Stock team</p>
                    </div>
                ) : (
                    <p className="footer-text">@Next Stock team</p>
                )}
            </div>
        </div>
    );
}
